import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Navigate, useLocation} from 'react-router-dom';
import {FormattedMessage, useIntl} from 'react-intl';

import {useGetTranslations} from 'utils/hooks/useGetTranslations';
import {firstAuthorizedRoute, TranslationKeys} from 'utils/constants/routeConstants';
import {IStore} from 'redux/interface';
import {startLoginAction} from 'redux/auth/authAsyncActions';

import MeetYourTeam from 'components/meet-your-team';
import MainArea from 'components/main-area';

import './styles.scss';
import CrossroadImg from '../../assets/image/library/skill-crossroad.svg';
import NotFoundInfo from '../../components/not-found-info';

export const Home: React.FunctionComponent = () => {
  const intl = useIntl();
  const {
    auth: {
      auth0User,
      isFinishAuthorization
    }
  } = useSelector((state: IStore) => state);
  const dispatch = useDispatch();

  useGetTranslations({
    translationKey: TranslationKeys.Home
  });
  const {state: appLocState} = useLocation();

  if (auth0User) {
    if (appLocState) {
      return <Navigate to={appLocState.from}/>;
    }

    return <Navigate to={firstAuthorizedRoute}/>;
  }
  if (!auth0User && appLocState && isFinishAuthorization) {
    if (appLocState) {
      dispatch(startLoginAction(appLocState.from.pathname + appLocState.from.hash));
    }

    return null;
  }

  return <div className="home">
    <h1 className="visually-hidden">
      <FormattedMessage
        id={'gritx.home.access.title'}
        defaultMessage={'Home page GritX website'}
      />
    </h1>
    <NotFoundInfo
      title={intl.formatMessage({
        id: 'gritx.home.content.appear.here',
        defaultMessage: 'Homepage content will appear here.'
      })}
      image={CrossroadImg}/>
  </div>;
};
