import React, {FunctionComponent, useState} from 'react';
import {FormattedMessage} from 'react-intl';

import Privacy from 'components/privacy';
import Term from 'components/term';
import ModalWindow from 'components/modal-window';
import setIsBodyScrolling from 'utils/helpers/setIsBodyScrolling';

import './styles.scss';

export const AdditionalInformation: FunctionComponent = () => {
  const [isShowPrivacy, setIsShowPrivacy] = useState(false);
  const [isShowTerms, setIsShowTerms] = useState(false);

  function openPrivacyWindow() {
    setIsShowPrivacy(true);
    setIsBodyScrolling(false);
  }

  function hidePrivacyWindow() {
    setIsBodyScrolling(true);
    setIsShowPrivacy(false);
  }

  function openTermsWindow() {
    setIsShowTerms(true);
    setIsBodyScrolling(false);
  }

  function hideTermsWindow() {
    setIsBodyScrolling(true);
    setIsShowTerms(false);
  }

  return <div className="additional-information-color">
    <div className="container container__wrapper">
      <div className="additional-information">
        <section className={'additional-information__copyright'}>
          <FormattedMessage
            id={'gritx.footer.copyright'}
            defaultMessage={'Confidential and Proprietary, WSC Technology, Inc., 2023'}
          />
        </section>
        {/*<section className="additional-information__privacy-terms">*/}
        {/*  <button className="additional-information__button" onClick={openPrivacyWindow}>*/}
        {/*    <FormattedMessage*/}
        {/*      id={'gritx.footer.privacy'}*/}
        {/*      defaultMessage={'privacy'}*/}
        {/*    />*/}
        {/*  </button>*/}
        {/*  <div className="additional-information__separator">|</div>*/}
        {/*  <button className="additional-information__button" onClick={openTermsWindow}>*/}
        {/*    <FormattedMessage*/}
        {/*      id={'gritx.footer.terms'}*/}
        {/*      defaultMessage={'terms'}*/}
        {/*    />*/}
        {/*  </button>*/}
        {/*</section>*/}
      </div>
    </div>
    {/*<ModalWindow show={isShowPrivacy} onHide={hidePrivacyWindow} className="modal-privacy-terms">*/}
    {/*  <Privacy/>*/}
    {/*</ModalWindow>*/}
    {/*<ModalWindow show={isShowTerms} onHide={hideTermsWindow} className="modal-privacy-terms">*/}
    {/*  <Term/>*/}
    {/*</ModalWindow>*/}
  </div>;
};
